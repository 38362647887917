/**
 * CkInputPassword Component
 *
 * `CkInputPassword` is a custom Input component built upon AntDesign's Input.Password and Form.Item.
 * It provides a customizable input field of password type. The component includes additional properties for the main wrapper and for the input itself.
 * 
 * @component
 * @example
 * <CkInputPassword inputProps={{ maxLength: 10 }} variant="default" />
 *
 * @extends {FormItemProps}
 *
 * @property {"default"|"rounded"} variant - Optional: Specifies the style of the input field. Possible values are `default` or `rounded`; defaults to `default`
 * @property {IInputProps} inputProps - Optional: Additional properties that are to be passed to the AntDesign Input.Password field
 * @property {boolean} allowClear - Optional: Enables the capability to clear values from the password input field
 * @property {ForwardedRef} ref - Optional: A forwarded ref to be applied to the Form.Item for potential use in parent components
 */
import React, { FC, ForwardedRef } from "react";
import { Input, Form, FormItemProps, InputProps, InputRef } from "antd";
import { CkIcon } from "../../atoms";
import "./styles.css";

interface IInputProps extends InputProps {
  ref?: ForwardedRef<InputRef>;
}
export interface CkInputPasswordProps extends FormItemProps {
  /**
   * Optional: Specifies the style of the input field. Possible values are `default` or `rounded`; defaults to `default`
   */
  variant?: "default" | "rounded";
  /**
   * Optional: Additional properties that are to be passed to the AntDesign Input.Password field.
   * inputProps extends AntD Input, see full docs on https://4x.ant.design/components/input/#Input.Password
   */
  inputProps?: IInputProps;
  /**
   * Optional: Additional CSS classes to apply to Form item
   */
  className?: string;
  /**
   * Optional: Enables the capability to clear values from the password input field
   */
  allowClear?: boolean;
  /**
   * Optional: A forwarded ref to be applied to the Form.Item for potential use in parent components
   */
  ref?: ForwardedRef<any>
}

/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * inputProps extends AntD Input, see full docs on https://4x.ant.design/components/input/#Input.Password
 */
const CkInputPassword: FC<CkInputPasswordProps> = ({ variant = "default", ref, allowClear, className, inputProps, ...props}) => (
  <Form.Item 
    className={["ck-input-password", variant, ...(className ? [className] : [])].join(" ")}
    {...(ref ? {ref} : {})}
    {...props}
  >
    <Input.Password
      {...(allowClear ? { allowClear: {
        clearIcon: <CkIcon name="close" />
      }} : {})}
      {...(inputProps || {})} 
    />
  </Form.Item>
)

export default CkInputPassword;
