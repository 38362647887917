/**
 * CkLink Component
 * 
 * `CkLink` is a component acting as a wrapper around the Link component from the `react-router-dom` library. It includes customizable properties to adjust styles and behavior including variant, color, size, and Google Tag Manager click event tracking.
 *
 * @component
 * @example
 * // This is how you can use the CkLink component
 * <CkLink to="/somewhere" variant="primary">Go Somewhere</CkLink>
 *
 * @extends {LinkProps}
 *
 * @property {ReactNode} children - Optional: Content to be wrapped by the link
 * @property {TCkLinkVariants} variant - Optional: Variant to use for the link style
 * @property {"primary" | "secondary" | "mariner" | "danger" | "white"} color - Optional: Color for the link style, defaults to "primary"
 * @property {"large" | "middle" | "small"} size - Optional: Size for the link style
 * @property {CkIconNames | ReactNode} icon - Optional: An icon component to display within the link
 * @property {boolean} block - Optional: If true, styles link as a block element
 * @property {boolean} underlined - Optional: If true, underlines text on link
 * @property {boolean} triggerGTMOnClick - Optional: If true, triggers a Google Tag Manager event upon clicking link
 * @property {string | object} to - Required: The URL/path where the link should navigate to
 */
import React, { FC, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import { CkIcon, CkIconNames } from "../../atoms";
import { gtmSetId } from "../../../utils";
import "./styles.css";

export type TCkLinkVariants = "primary" | "secondary" | "dashed" | "link" | "text";

export interface CkLinkProps extends LinkProps {
  /**
   * Optional: Content to be wrapped by the link
   */
  children?: ReactNode;
  /**
   *  Optional: Variant to use for the link style
   */
  variant?: TCkLinkVariants;
  /**
   *  Optional: Color for the link style, defaults to "primary"
   */
  color?: "primary" | "secondary" | "mariner" | "danger" | "white";
  /**
   * Optional: Size for the link style
   */
  size?: "large" | "middle" | "small";
  /**
   * Optional:
   * If a string is provided, component will try to import CkIcon element. 
   * If ReactNode is provided, this will be used.
   */
  icon?: CkIconNames | ReactNode;
  /**
   * Optional: If true, underlines text on link
   */
  underlined?: boolean;
  /**
   * Optional: If true, triggers a Google Tag Manager event upon clicking link
   */
  triggerGTMOnClick?: boolean;
  /**
   * Required: The URL/path where the link should navigate to
   */
  to: string | object;
}

/**
 * Extends React Router Dom props. Looks like CkButton, talk like a CkButton, moves like a CkButton, but is a Link =).
 * See full doc on https://v5.reactrouter.com/web/api/Link
 */
const CkLink: FC<CkLinkProps> = ({children, to, block = true, underlined, color = "primary", variant = "primary", size = "large", icon, className, triggerGTMOnClick, ...props}) => (
  <Link 
    to={to}
    className={[
      "ck-link", 
      `size-${size}`, 
      `variant-${variant}`, 
      `color-${color}`,
      ...(block ? ["block"] : []), 
      ...(icon ? ["iconed"] : []), 
      ...(icon && !!children === false ? ["icon-only"] : []), 
      ...(underlined ? ["underlined"] : []), 
      ...(className ? [className] : [])
    ].join(" ")}
    {...(props.id && triggerGTMOnClick ? {onClick: (e) => {
      gtmSetId(e.currentTarget);
      props?.onClick && props.onClick(e); 
    }} : {})}
    {...props}
  >
    {icon && typeof icon === "string" && <CkIcon name={icon as CkIconNames} />}
    {icon && typeof icon !== "string" && icon}
    {children && icon && <span>{children}</span>}
    {children && !!icon === false && children}
  </Link>
);

export default CkLink;
