/** 
 * `CkEmptyImage` Functional Component.
 *
 * This is a wrapper component around the `Dragger` component from
 * the `antd` library, which provides a drag-and-drop zone for file uploading.
 * 
 * @component
 * @param {object} props - Props passed down to the CkEmptyImage component
 * @param {string} props.className - Optional. Add additional CSS class/classes to the component
 * @param {"large"|"small"} props.size - Optional. Size of the CkEmptyImage component. Accepts either `"large"` or `"small"`. If not specified, defaults to "small".
 * @param {ForwardedRef} props.ref - Optional. Allows a React.Ref to be forwarded to the component
 *
 * @example
 * // To use this component
 * import CkEmptyImage from 'path-to-component';
 * // ...
 * <CkEmptyImage className="custom-class" size="large" {...otherDraggerProps} />
 *
 * @see {@link https://ant.design/components/upload} for `antd` Upload documentation
 *
 */
import React, { FC, ForwardedRef } from "react";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { CkIcon } from "../../atoms";

import "./styles.css";

export interface CkEmptyImageProps extends UploadProps {
  /**
   * Optional. Add additional CSS class/classes to the component
   */
  className?: string;
  /**
   * Optional. Size of the CkEmptyImage component. Accepts either `"large"` or `"small"`. If not specified, defaults to "small".
   */
  size?: "large" | "small";
  /**
   * Optional. Allows a React.Ref to be forwarded to the component
   */
  ref?: ForwardedRef<any>;
}

/**
 * This component extends antd Upload
 * See full docs on https://ant.design/components/upload
 */
const CkEmptyImage: FC<CkEmptyImageProps> = ({ className, size, ref, ...props }) => (
  <Dragger
    className={[
      ...(size ? ["ck-empty-img-" + size] : ["ck-empty-img-small"]),
      ...(className ? [className] : []),
    ].join(" ")}
    {...(ref ? { ref } : {})}
    {...props}
  >
    <div className="ck-empty-img-container">
      <CkIcon name="add-image" className="ck-empty-img-icon" />
      <p className="primary-color bold medium-font padding-bottom-8">
        Agregar una imagen
      </p>
      <p className="gray-color small-font bold zero-margin">
        Formatos permitidos:{" "}
      </p>
      <p className="gray-color small-font zero-margin">JPG, JPEG, PNG</p>
      <p className="gray-color small-font bold zero-margin">Tamaño máximo: </p>
      <p className="gray-color small-font zero-margin">20 MB</p>
    </div>
  </Dragger>
);

export default CkEmptyImage;
