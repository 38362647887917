/**
 * CkNavigationButton Component
 *
 * `CkNavigationButton` is a custom Button component built upon Ant Design's Button. 
 * It provides navigation functionality out of the box, directing users to different locations 
 * within your application based on provided props.
 *
 * @component
 * @example
 * // Clicking this button takes the user forward to the "/next-page"
 * <CkNavigationButton direction="forward" to="/next-page">Go to next page</CkNavigationButton>
 *
 * @extends {ButtonProps}
 *
 * @property {string | IHistory} to - Optional: The URL or location object specifying where to navigate when the button is clicked and the direction prop is set to "forward"
 * @property {"forward" | "back"} direction - Specifies the navigation direction. It is either "forward" (takes the user to a specified route) or "back" (takes the user to the previous page in the history stack or the home page if the history length is 1)
 * @property {boolean} triggerGTMOnClick - Optional: If true, triggers a Google Tag Manager event upon click
 * @property {CkIconNames | ReactNode} icon - Optional: Icon displayed within the button
 */
import React, { FC, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonProps } from "antd";
import { gtmSetId } from "../../../utils";
import { CkIcon, CkIconNames } from "../../atoms";
import "./styles.css";

interface IHistory {
  hash?: string;
  pathname: string;
  search?: string;
  state?: string | undefined;
}

/**
 * This component extends all base properties from Antd Button
 */
export interface CkNavigationButtonProps extends ButtonProps {
  /**
   * Optional: The URL or location object specifying where to navigate when the button is clicked and the direction prop is set to "forward"
   * This will be the direction to navigate, when direction is set to "forward"
   * Accepts an string and IHistory object (check Interface defined some lines above this one )
   * Users history.push, so it accepts an object like ReactRouterDom Link https://v5.reactrouter.com/web/api/Link
   */
  to?: string | IHistory;
  /**
   *  Specifies the navigation direction. It is either "forward" (takes the user to a specified route) or "back" (takes the user to the previous page in the history stack or the home page if the history length is 1)
   * If direction is set to fordward, and "to" have a valid value, button will trigger navigation to "to" onClick
   * If direction is set to fordward, and "to" have a valid value, button will not trigger any navigation
   * If history length is equals to 1 and direction is set to back, user will be redirected to home page
   */
  direction: "forward" | "back";
  /**
   * Optional: If true, triggers a Google Tag Manager event upon click
   */
  triggerGTMOnClick?: boolean;
  /**
   * Optional:
   * If a string is provided, component will try to import CkIcon element. 
   * If ReactNode is provided, this will be used.
   */
  icon?: CkIconNames | ReactNode;
}

/**
 * Extends AntD Button props
 * See full doc on https://4x.ant.design/components/button/
 */
const CkNavigationButton: FC<CkNavigationButtonProps> = ({
  onClick,
  className = "",
  to,
  direction,
  children,
  triggerGTMOnClick,
  icon,
  ...props
}) => {
  const history = useHistory();
  const navigate = (e) => {
    if (!!onClick) onClick(e);
    if (direction === "forward" && !!to) {
      history.push(to);
    }
    if (direction === "back" && history.length > 1) {
      history.goBack();
    }
    if (direction === "back" && history.length <= 1) {
      history.push("/");
    }
  };
  return (
    <Button
      className={`ck-navigation-button ${className}`}
      onClick={(e) => navigate(e)}
      {...(icon && typeof icon === "string" ? {icon: <CkIcon name={icon as CkIconNames} />} : {})}
      {...(icon && typeof icon !== "string" ? {icon} : {})}
      {...(props.id && triggerGTMOnClick ? {onClick: (e) => {
        gtmSetId(e.currentTarget);
        onClick && onClick(e); 
      }} : {})}
      {...props}
    >
      {!!children && children}
    </Button>
  );
};

export default CkNavigationButton;
