/**
 * CkInputNumber Component
 *
 * `CkInputNumber` is a custom Input Number component built upon Ant Design's InputNumber and Form.Item components.
 * It provides an organized way to use input number field with additional customizations such as displaying a both control icons as caret up and down icons.
 *
 * @component
 * @example
 * // This is how you can use the CkInputNumber component with a variant and ref
 * <CkInputNumber variant="rounded" ref={myRef} />
 *
 * @extends {FormItemProps}
 *
 * @property {"default"|"rounded"} variant - Optional: Determines the style of the input field, defaults to "default"
 * @property {InputNumberProps} inputProps - Optional: Additional properties to pass to the Ant Design InputNumber component
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {boolean} allowClear - Optional: Determines whether the clear icon to empty the input field value is displayed
 * @property {ForwardedRef<any>} ref - Optional: A forwarded ref to be applied to the Form.Item for potential use in parent components
 */
import React, { FC, ForwardedRef } from "react";
import { InputNumber, Form, FormItemProps, InputNumberProps } from "antd";
import { CkIcon } from "../../atoms";
import "./styles.css";

export interface CkInputNumberProps extends FormItemProps {
  /**
   * Optional: Determines the style of the input field, defaults to "default"
   */
  variant?: "default" | "rounded";
  /**
   * Optional: Additional properties to pass to the Ant Design InputNumber component.
   * inputProps extends AntD InputNumber, see full docs on https://4x.ant.design/components/input-number/
   */
  inputProps?: InputNumberProps;
  /**
   * Optional: Additional CSS classes to apply to Form item
   */
  className?: string;
  /**
   * Optional: Determines whether the clear icon to empty the input field value is displayed
   */
  allowClear?: boolean;
  /**
   * Optional: A forwarded ref to be applied to the Form.Item for potential use in parent components
   */
  ref?: ForwardedRef<any>
}

/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * inputProps extends AntD InputNumber, see full docs on https://4x.ant.design/components/input-number/
 */
const CkInputNumber: FC<CkInputNumberProps> = ({ variant = "default", ref, allowClear, className, inputProps, ...props}) => (
  <Form.Item 
    className={["ck-input-number", variant, ...(className ? [className] : [])].join(" ")}
    {...(ref ? {ref} : {})}
    {...props}
  >
    <InputNumber 
      controls={{
        upIcon: <CkIcon name={"caret-up"} />,
        downIcon: <CkIcon name={"caret-down"} />,
      }}
      {...(inputProps || {})} 
    />
  </Form.Item>
)

export default CkInputNumber;
