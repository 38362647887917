/**
 * CkRadio Component
 *
 * `CkRadio` is a custom radio group component built upon Ant Design's Radio and Form.Item. It is intended to provide a group of related options from which the user can select a single option.
 *
 * @component
 * @example
 * <CkRadio 
 *   options={[
 *     { label: "Option 1", value: "1" },
 *     { label: "Option 2", value: "2" }
 *   ]}
 * />
 *
 * @extends {FormItemProps}
 *
 * @property {string} className - Optional: Additional CSS classes to apply to the Form.Item containing the radio group
 * @property {RadioGroupProps} radioGroupProps - Optional: Additional properties to pass to the Ant Design Radio.Group
 * @property {CkRadioOptionProps[]} options - The radio buttons within the group, specified as an array of objects containing `label` (the display text) and `value` (the corresponding value) properties.
 * @property {boolean} noMargin - Optional: If true, removes the margin from the Form.Item containing the radio group
 * @property {OrientationProps | ResponsiveOrientationProps} buttonsDirection - Optional: Specifies the layout or direction of radio buttons in the group
 */
import React, { FC } from "react";
import { Radio, Form, FormItemProps, RadioGroupProps, RadioProps } from "antd";
import {
  translateOrientation,
  OrientationProps,
  ResponsiveOrientationProps,
} from "../../../utils";
import "./styles.css";

export interface CkRadioOptionProps extends RadioProps {
  label: string;
  value: string;
}
export interface CkRadioProps extends FormItemProps {
  /**
   * Optional: Additional CSS classes to apply to the Form.Item containing the radio group
   */
  className?: string;
  /**
   * Optional: Additional properties to pass to the Ant Design Radio.Group
   * radioGroupProps extends AntD Radio.Group, see full docs on https://4x.ant.design/components/radio/#RadioGroup
   */
  radioGroupProps?: RadioGroupProps;
  /**
   * The radio buttons within the group, specified as an array of objects containing `label` (the display text) and `value` (the corresponding value) properties.
   * extends https://4x.ant.design/components/radio/#Radio/Radio.Button
   */
  options: CkRadioOptionProps[];
  /**
   * Optional: If true, removes the margin from the Form.Item containing the radio group
   */
  noMargin?: boolean;
  /**
   * Optional: Specifies the layout or direction of radio buttons in the group
   * For responsive, 768px is used as breakpoint
   */
  buttonsDirection?: OrientationProps | ResponsiveOrientationProps;
}
/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * radioGroupProps extends AntD Radio.Group, see full docs on https://4x.ant.design/components/radio/#RadioGroup
 */
const CkRadio: FC<CkRadioProps> = ({
  className,
  radioGroupProps,
  options,
  noMargin,
  buttonsDirection,
  ...props
}) => (
  <Form.Item
    className={[
      "ck-radio",
      ...(className ? [className] : []),
      ...(noMargin ? ["no-margin"] : []),
      ...(buttonsDirection ? [translateOrientation(buttonsDirection)] : []),
    ].join(" ")}
    {...props}
  >
    <Radio.Group {...(radioGroupProps || {})}>
      {options &&
        options.map((option, id) => (
          <Radio {...option} key={`radio-${id}`}>
            {option.label && option.label}
          </Radio>
        ))}
    </Radio.Group>
  </Form.Item>
);

export default CkRadio;
